// Chakra imports
import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useColorModeValue,
    FormLabel,
    useToast,
    Select
  } from '@chakra-ui/react';
  // Custom components
  
  import Card from 'components/card/Card';
  import InputField from 'components/fields/InputField';
  import TextField from 'components/fields/TextField';
  import React, { useState,useEffect } from 'react';
  import Dropzone from 'react-dropzone';
  import axios from 'axios';
  import { API_BASE_URL } from 'constants/apiConstants';
  import { ASSETS_URL } from 'constants/apiConstants';
  import { useNavigate } from 'react-router-dom';
  import Multiselect from 'multiselect-react-dropdown';
  import SortableList, { SortableItem } from 'react-easy-sort';
  import { makeStyles } from '@mui/styles';
  import arrayMove from "array-move";

  const useStyles = makeStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      userSelect: "none"
    },
    item: {
      position: "relative",
      flexShrink: 0,
      display: "flex",
      margin: 8,
      cursor: "grab",
      userSelect: "none",
      boxShadow: "0px 6px 6px -3px rgba(0, 0, 0, 0.2)",
    },
    image: {
      width: 150,
      height: 150,
      pointerEvents: "none",
      borderRadius: "8px",
    },
    button: {
      position: "absolute",
      bottom: 0,
      right: 0
    },
    dragged: {
      boxShadow:
        "0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
      "& button": {
        opacity: 0
      }
    }
  });
  
  export default function New() {
  

  }
  