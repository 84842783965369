export const columnsDataUsersOverview = [
  {
    Header: "ACTIONS",
    accessor: "agent_id",
  },
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Profile Image",
    accessor: "profile_picture",
  },
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "USER NAME",
    accessor: "user_name",
  },
  {
    Header: "Phone Number",
    accessor: "phone_number",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "Account Type",
    accessor: "account_type",
  },
  {
    Header: "Created At",
    accessor: "date",
  },
];
