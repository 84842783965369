// Chakra imports
import {
    Box,
    Button,
    Flex,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useColorModeValue,
    useToast,
  } from '@chakra-ui/react';
  // Custom components
  
  import Card from 'components/card/Card';
  import InputField from 'components/fields/InputField';
  import React, { useState } from 'react';
  import axios from 'axios';
  import { API_BASE_URL } from 'constants/apiConstants';
  import { useNavigate } from 'react-router-dom';
  
  export default function NewBusinessType() {
    const navigate = useNavigate();
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const [activeBullets, setActiveBullets] = useState({
      user: true,
    });
    const [input,setInput] = useState({
      title: "",
    });
    const [errors, setErrors] = useState({});
    const toast = useToast();
    const [businessTypeId, setBusinessTypeId] = useState('');
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setInput({
        ...input,
        [name]: value,
      });
    };
  
    const validateForm = (data) => {
      const errors = {};
      if (!data.title) {
        errors.title = 'This field is required';
      }
      return errors;
    };
  
    const handleSubmitBusinessTypeInfo = (e) => {
      e.preventDefault();
      const newErrors = validateForm(input);
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        const payload = {
          "title":input.title,
          "businessTypeId": businessTypeId
        }
        axios.post(API_BASE_URL+'business/type/store',payload,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          }
        })
        .then(function (response){
          if(response.data.apistatus === true){
            setBusinessTypeId(response.data.data.businessTypeId)
            toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
            navigate("/admin/business-type/overview");
          }else if(response.data.apistatus === false){
            toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
          }
        })
        .catch(function (error){
          toast({ title: error,status: 'error',isClosable: true,position: 'top'})
        })
      }
    };
  
    const userTab = React.useRef();
  
    return (
      <Flex
          direction="column"
          minH="100vh"
          align="center"
          pt={{ sm: '125px', lg: '75px' }}
          position="relative"
        >
          <Box
            h="45vh"
            bgGradient="linear(to-b, brand.400, brand.600)"
            position="absolute"
            w="100%"
            borderRadius="20px"
          />
    
          <Tabs
            variant="unstyled"
            zIndex="0"
            mt={{ base: '60px', md: '165px' }}
            display="flex"
            flexDirection="column"
          >
            <TabList
              display="flex"
              alignItems="center"
              alignSelf="center"
              justifySelf="center"
            >
              <Tab
                _focus={{ border: '0px', boxShadow: 'unset' }}
                ref={userTab}
                w={{ sm: '120px', md: '250px', lg: '300px' }}
                onClick={() =>
                  setActiveBullets({
                    user: true,
                    address: false,
                    profile: false,
                  })
                }
              >
                <Flex
                  direction="column"
                  justify="center"
                  align="center"
                  position="relative"
                  _before={{
                    content: "''",
                    width: { sm: '120px', md: '250px', lg: '300px' },
                    height: '3px',
                    bg: activeBullets.address ? 'white' : 'brand.400',
                    left: { sm: '12px', md: '30px' },
                    top: {
                      sm: activeBullets.address ? '6px' : '4px',
                      md: null,
                    },
                    position: 'absolute',
                    bottom: activeBullets.address ? '40px' : '38px',
    
                    transition: 'all .3s ease',
                  }}
                >
                  <Box
                    zIndex="1"
                    border="2px solid"
                    borderColor={activeBullets.address ? 'white' : 'brand.400'}
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    w="16px"
                    h="16px"
                    mb="8px"
                    borderRadius="50%"
                  />
                  <Text
                    color={activeBullets.address ? 'white' : 'gray.300'}
                    fontWeight={activeBullets.address ? 'bold' : 'normal'}
                    display={{ sm: 'none', md: 'block' }}
                  >
                    Business Type Info
                  </Text>
                </Flex>
              </Tab>
            </TabList>
            <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
              <TabPanel
                w={{ sm: '330px', md: '700px', lg: '850px' }}
                p="0px"
                mx="auto"
              >
                <Card p="30px">
                  <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                  Business Type Info
                  </Text>
                  <Flex direction="column" w="100%">
                      <Stack direction="column" spacing="20px">


                        <Flex direction="column">
                          <InputField
                              mb="0px"
                              id="title"
                              placeholder="eg. Coffee Shop"
                              label="Title *"
                              name="title"
                              onChange={handleChange}
                              value={input.title}
                          />
                          {errors.title &&
                            <span className="error-message">
                                {errors.title}
                            </span>
                          }
                        </Flex>

                      </Stack>
                      <Flex justify="space-between" mt="24px">
                        <Button
                          variant="darkBrand"
                          fontSize="sm"
                          borderRadius="16px"
                          w={{ base: '128px', md: '148px' }}
                          h="46px"
                          ms="auto"
                          type='submit'
                          onClick={handleSubmitBusinessTypeInfo}
                        >
                          Next
                        </Button>
                      </Flex>
                  </Flex>
                </Card>
              </TabPanel>
            </TabPanels>
          </Tabs>
      </Flex>
    );
  }
  