// Chakra imports
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  FormLabel,
  useToast,
  Select
} from '@chakra-ui/react';
// Custom components

import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import React, { useState,useEffect } from 'react';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { API_BASE_URL } from 'constants/apiConstants';
import { ASSETS_URL } from 'constants/apiConstants';
import { useParams,useNavigate } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import SortableList, { SortableItem } from 'react-easy-sort';
import arrayMove from "array-move";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      userSelect: "none"
    },
    item: {
      position: "relative",
      flexShrink: 0,
      display: "flex",
      margin: 8,
      cursor: "grab",
      userSelect: "none",
      boxShadow: "0px 6px 6px -3px rgba(0, 0, 0, 0.2)",
    },
    image: {
      width: 150,
      height: 150,
      pointerEvents: "none",
      borderRadius: "8px",
    },
    button: {
      position: "absolute",
      bottom: 0,
      right: 0
    },
    dragged: {
      boxShadow:
        "0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
      "& button": {
        opacity: 0
      }
    }
  });

export default function EditBusiness() {
  const navigate = useNavigate();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [activeBullets, setActiveBullets] = useState({
    user: true,
    address: false,
  });
  const [errors, setErrors] = useState({});
  const toast = useToast();
  const [businessTypeId, setBusinessTypeId] = useState('');
  const { id } = useParams();
  const [input,setInput] = useState([]);

  const getBusinessType = (e) => {
    axios.get(API_BASE_URL+'get/business/type/'+id,{
        headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
    })
    .then(function (response){
        setBusinessTypeId(response.data.data.id);
        setInput({
          title: response.data.data.title,
        });
    })
    .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
  };

  const validateForm = (data) => {
    const errors = {};
    if (!data.title) {
      errors.title = 'This field is required';
    }
    return errors;
  };

  const handleSubmitBusinessTypeInfo = (e) => {
    e.preventDefault();
    const newErrors = validateForm(input);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const payload = {
        "title":input.title,
        "businessTypeId": businessTypeId
      }
      axios.post(API_BASE_URL+'business/type/store',payload,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then(function (response){
        if(response.data.apistatus === true){
          toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
          navigate("/admin/business-type/overview");
        }else if(response.data.apistatus === false){
          toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
        }
      })
      .catch(function (error){
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    }
  };

  useEffect(() => {
    getBusinessType();
  },[])

  const userTab = React.useRef();

  return (
    <Flex
        direction="column"
        minH="100vh"
        align="center"
        pt={{ sm: '125px', lg: '75px' }}
        position="relative"
      >
        <Box
          h="45vh"
          bgGradient="linear(to-b, brand.400, brand.600)"
          position="absolute"
          w="100%"
          borderRadius="20px"
        />
  
        <Tabs
          variant="unstyled"
          zIndex="0"
          mt={{ base: '60px', md: '165px' }}
          display="flex"
          flexDirection="column"
        >
          <TabList
            display="flex"
            alignItems="center"
            alignSelf="center"
            justifySelf="center"
          >
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={userTab}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
              onClick={() =>
                setActiveBullets({
                  user: true,
                  address: false,
                  profile: false,
                })
              }
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: '120px', md: '250px', lg: '300px' },
                  height: '3px',
                  bg: activeBullets.address ? 'white' : 'brand.400',
                  left: { sm: '12px', md: '30px' },
                  top: {
                    sm: activeBullets.address ? '6px' : '4px',
                    md: null,
                  },
                  position: 'absolute',
                  bottom: activeBullets.address ? '40px' : '38px',
  
                  transition: 'all .3s ease',
                }}
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.address ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.address ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.address ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  Business Type Info
                </Text>
              </Flex>
            </Tab>
          </TabList>
          <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                Business Type Info
                </Text>
                <Flex direction="column" w="100%">
                    <Stack direction="column" spacing="20px">


                      <Flex direction="column">
                        <InputField
                            mb="0px"
                            id="title"
                            placeholder="eg. Coffee Shop"
                            label="Title *"
                            name="title"
                            onChange={handleChange}
                            value={input.title}
                        />
                        {errors.title &&
                          <span className="error-message">
                              {errors.title}
                          </span>
                        }
                      </Flex>

                    </Stack>
                    <Flex justify="space-between" mt="24px">
                      <Button
                        variant="darkBrand"
                        fontSize="sm"
                        borderRadius="16px"
                        w={{ base: '128px', md: '148px' }}
                        h="46px"
                        ms="auto"
                        type='submit'
                        onClick={handleSubmitBusinessTypeInfo}
                      >
                        Next
                      </Button>
                    </Flex>
                </Flex>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
    </Flex>
  );
}
