// Chakra imports
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  FormLabel,
  useToast,
  Select
} from '@chakra-ui/react';
// Custom components

import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import React, { useState,useEffect } from 'react';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { API_BASE_URL } from 'constants/apiConstants';
import { ASSETS_URL } from 'constants/apiConstants';
import { useParams,useNavigate } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import SortableList, { SortableItem } from 'react-easy-sort';
import arrayMove from "array-move";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      userSelect: "none"
    },
    item: {
      position: "relative",
      flexShrink: 0,
      display: "flex",
      margin: 8,
      cursor: "grab",
      userSelect: "none",
      boxShadow: "0px 6px 6px -3px rgba(0, 0, 0, 0.2)",
    },
    image: {
      width: 150,
      height: 150,
      pointerEvents: "none",
      borderRadius: "8px",
    },
    button: {
      position: "absolute",
      bottom: 0,
      right: 0
    },
    dragged: {
      boxShadow:
        "0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
      "& button": {
        opacity: 0
      }
    }
  });

export default function EditBusiness() {
  const navigate = useNavigate();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [activeBullets, setActiveBullets] = useState({
    user: true,
    address: false,
  });
  const [errors, setErrors] = useState({});
  const toast = useToast();
  const [businessImage, setBusinessImage] = useState('');
  const [businessId, setBusinessId] = useState('');
  const { id } = useParams();
  const [input,setInput] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [standoutAmenities, setStandoutAmenities] = useState([]);
  const [businessAmenities,setBusinessAmenities] = useState([]);
  const [mediaImages,setMediaImages] = useState([]);
  const [menuImages,setMenuImages] = useState([]);

  const getBusinessTypes = (e) => {
    axios.get(API_BASE_URL+'get/business/types',{
        headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
    })
    .then(function (response){
      setBusinessTypes(response.data.data);
    }) 
    .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
    })
  };

  const getStandoutAmenities = () => {
    axios.get(API_BASE_URL+'get/standout/amenity',{
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      }
    })
    .then((response) => {
      setStandoutAmenities(response.data.data)
    })
    .catch((error) => {
      toast({ title: error,status: 'error',isClosable: true,position: 'top'})
    })
  };

  const getBusiness = (e) => {
    axios.get(API_BASE_URL+'get/business/'+id,{
        headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
    })
    .then(function (response){
        setBusinessId(response.data.data.business.id);
        setInput({
          business_name: response.data.data.business.name,
          username: response.data.data.business.user_name,
          email: response.data.data.business.email,
          business_type: (response.data.data.business.business_info) ? response.data.data.business.business_info.business_type_id : '',
          phone_number: response.data.data.business.phone_number,
          website: response.data.data.business.website,
          verified_type: response.data.data.business.is_verified,
          brief_bio: response.data.data.business.brief_bio,
          business_start_time: (response.data.data.business.business_info) ? response.data.data.business.business_info.business_start_time : '',
          business_end_time: (response.data.data.business.business_info) ? response.data.data.business.business_info.business_end_time : '',
          business_location: (response.data.data.business.business_info) ? response.data.data.business.business_info.business_location : '',
          business_lat: (response.data.data.business.business_info) ? response.data.data.business.business_info.business_latitude : '',
          business_lng: (response.data.data.business.business_info) ? response.data.data.business.business_info.business_longitude : '',
          standout_amenity_ids: response.data.data.businessAmenitiesArray,
        });
        setBusinessAmenities(response.data.data.businessAmenities);
        setBusinessImage(response.data.data.business.profile_picture);
        setMediaImages(response.data.data.business.mediaImages);
        setMenuImages(response.data.data.business.menuImages);
    })
    .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
    if((name === 'username' || name === 'email') && value != ''){
      const payload = {
        "name":name,
        "value":value,
        "businessId": businessId
      }
      axios.post(API_BASE_URL+'check/value',payload,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then(function (response){
        if(response.data.apistatus === false){
          toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
          if(name === 'username'){
            setInput({
              'username': '',
            });
          }else if(name === 'email'){
            setInput({
              'email': '',
            });
          }
        }
      })
      .catch(function (error){
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    }
  };

  const handleAmenitySelectValue = (value) => {
    input.standout_amenity_ids = [];
    value.forEach(function(v) {
      input.standout_amenity_ids.push(v.id);
    });
  };

  const validateForm = (data) => {
    const errors = {};
    if (!data.business_name) {
      errors.business_name = 'This field is required';
    }
    
    if (!data.username) {
      errors.username = 'Username field is required';
    }

    if (!data.business_type) {
      errors.business_type = 'This field is required';
    }

    if (!data.business_start_time) {
      errors.business_start_time = 'This field is required';
    }

    if (!data.business_end_time) {
      errors.business_end_time = 'This field is required';
    }

    if (!data.verified_type) {
      errors.verified_type = 'This field is required';
    }
    return errors;
  };

  const handleSubmitBusinessInfo = (e) => {
    e.preventDefault();
    const newErrors = validateForm(input);
    console.log('input',input);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const payload = {
        "business_name":input.business_name,
        "username":input.username,
        "email":input.email,
        "business_type":input.business_type,
        "phone_number":input.phone_number,
        "verified_type":input.verified_type,
        "website":input.website,
        "brief_bio":input.brief_bio,
        "business_start_time": input.business_start_time,
        "business_end_time": input.business_end_time,
        "standout_amenity_ids": input.standout_amenity_ids,
        "businessId": businessId
      }
      axios.post(API_BASE_URL+'business/store',payload,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then(function (response){
        if(response.data.apistatus === true){
          toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
          addressTab.current.click();
        }else if(response.data.apistatus === false){
          toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
        }
      })
      .catch(function (error){
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    }
  };

  const validateAddressForm = (data) => {
    const errors = {};
    if (!data.business_location) {
      errors.business_location = 'Address is required';
    }

    if (!data.business_lat) {
      errors.business_lat = 'Address latitude is required';
    }

    if (!data.business_lng) {
      errors.business_lng = 'Address longitude is required';
    }
    return errors;
  };

  const handleSubmitBusinessAddress = (e) => {
    e.preventDefault();
    const newErrors = validateAddressForm(input);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {

      if(businessId === ''){
        toast({ title: 'Please fill business info details first.',status: 'error',isClosable: true,position: 'top'})
        return false;
      }

      const payload = {
        "business_location":input.business_location,
        "business_lat":input.business_lat,
        "business_lng":input.business_lng,
        "businessId":businessId,
      }
      axios.post(API_BASE_URL+'business/update-location',payload,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then(function (response){
        if(response.data.apistatus === true){
          toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
          mediaTab.current.click();
        }else if(response.data.apistatus === false){
          toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
        }
      })
      .catch(function (error){
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    }
  }

  const handleBusinessImageUpload = (acceptedFiles) => {
    if(acceptedFiles[0].type !== 'image/png' && acceptedFiles[0].type !== 'image/jpg' && acceptedFiles[0].type !== 'image/jpeg'){
      toast({ title: 'File type not supported. We accept JPG, PNG, JPEG only.',status: 'error',isClosable: true,position: 'top'})
      return false;
    }
    
    if(businessId === ''){
      toast({ title: 'Please fill business info details first.',status: 'error',isClosable: true,position: 'top'})
      return false;
    }

    const formData = new FormData();
    formData.append("businessImage",acceptedFiles[0]);
    formData.append("business_id",businessId);
    axios.post(API_BASE_URL+'business/upload/image',formData,{
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      }
    })
    .then(function (response){
      if(response.data.apistatus === true){
        setBusinessImage(response.data.data)
        toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
      }else if(response.data.apistatus === false){
        toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
      }
    })
    .catch((error) => {
      toast({ title: error,status: 'error',isClosable: true,position: 'top'})
    })
  }

  const removeImage = (type,image) => {
    const payload = {
        'business_id': businessId,
        'type': type,
        'image': image
    }
    axios.post(API_BASE_URL+'delete/business-image',payload,{
        headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
    })
    .then(function (response){
        if(type === 1){
            setBusinessImage(null)
        }
        if(type === 2){
          const updatedTasks = mediaImages.filter(item => item !== image);  
          setMediaImages(updatedTasks);
        }

        if(type === 3){
          const updatedMenuTasks = menuImages.filter(item => item !== image);  
          setMenuImages(updatedMenuTasks);
        }
    })
    .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
    })
  };

  const handleMediaImageUpload = (acceptedFiles) => {
    if(businessId === ''){
      toast({ title: 'Please fill location details first.',status: 'error',isClosable: true,position: 'top'})
      return false;
    }

    acceptedFiles.forEach((file) => {
      const formData = new FormData();
      formData.append("mediaImage",file);
      formData.append("business_id",businessId);
      axios.post(API_BASE_URL+'business/upload/media-images',formData,{
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then(function (response){
        if(response.data.apistatus === true){
          setMediaImages(response.data.data);
          toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
        }else if(response.data.apistatus === false){
          toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
        }
      })
      .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    });
  }

  const handleMenuImageUpload = (acceptedFiles) => {
    if(businessId === ''){
      toast({ title: 'Please fill location details first.',status: 'error',isClosable: true,position: 'top'})
      return false;
    }

    acceptedFiles.forEach((file) => {
      const formData = new FormData();
      formData.append("menuImage",file);
      formData.append("business_id",businessId);
      axios.post(API_BASE_URL+'business/upload/menu-images',formData,{
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then(function (response){
        if(response.data.apistatus === true){
          setMenuImages(response.data.data);
          toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
        }else if(response.data.apistatus === false){
          toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
        }
      })
      .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    });
  }

  const classes = useStyles();

  const redirectToListing = (e) => {
    if(businessId === ''){
      toast({ title: 'Please fill business info details first.',status: 'error',isClosable: true,position: 'top'})
      return false;
    }
    navigate("/admin/business/overview");
  }

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setMediaImages((array) => arrayMove(array, oldIndex, newIndex));
  };

  useEffect(() => {
    getBusiness();
    getBusinessTypes();
    getStandoutAmenities();
  },[])

  const userTab = React.useRef();
  const addressTab = React.useRef();
  const mediaTab = React.useRef();

  return (
    <Flex
        direction="column"
        minH="100vh"
        align="center"
        pt={{ sm: '125px', lg: '75px' }}
        position="relative"
      >
        <Box
          h="45vh"
          bgGradient="linear(to-b, brand.400, brand.600)"
          position="absolute"
          w="100%"
          borderRadius="20px"
        />
  
        <Tabs
          variant="unstyled"
          zIndex="0"
          mt={{ base: '60px', md: '165px' }}
          display="flex"
          flexDirection="column"
        >
          <TabList
            display="flex"
            alignItems="center"
            alignSelf="center"
            justifySelf="center"
          >
            <Tab
                            _focus={{ border: '0px', boxShadow: 'unset' }}
                            ref={userTab}
                            w={{ sm: '120px', md: '250px', lg: '300px' }}
                            onClick={() =>
                              setActiveBullets({
                                user: true,
                                address: false,
                                profile: false,
                              })
                            }
                          >
                            <Flex
                              direction="column"
                              justify="center"
                              align="center"
                              position="relative"
                              _before={{
                                content: "''",
                                width: { sm: '120px', md: '250px', lg: '300px' },
                                height: '3px',
                                bg: activeBullets.address ? 'white' : 'brand.400',
                                left: { sm: '12px', md: '30px' },
                                top: {
                                  sm: activeBullets.address ? '6px' : '4px',
                                  md: null,
                                },
                                position: 'absolute',
                                bottom: activeBullets.address ? '40px' : '38px',
                
                                transition: 'all .3s ease',
                              }}
                            >
                              <Box
                                zIndex="1"
                                border="2px solid"
                                borderColor={activeBullets.address ? 'white' : 'brand.400'}
                                bgGradient="linear(to-b, brand.400, brand.600)"
                                w="16px"
                                h="16px"
                                mb="8px"
                                borderRadius="50%"
                              />
                              <Text
                                color={activeBullets.address ? 'white' : 'gray.300'}
                                fontWeight={activeBullets.address ? 'bold' : 'normal'}
                                display={{ sm: 'none', md: 'block' }}
                              >
                                Business Info
                              </Text>
                            </Flex>
            </Tab>
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={addressTab}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
              onClick={() =>
                setActiveBullets({
                  user: true,
                  address: true,
                  media: false,
                })
              }
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: '120px', md: '250px', lg: '300px' },
                  height: '3px',
                  bg: activeBullets.media ? 'white' : 'brand.400',
                  left: { sm: '12px', md: '32px' },
                  top: '6px',
                  position: 'absolute',
                  bottom: activeBullets.media ? '40px' : '38px',

                  transition: 'all .3s ease',
                }}
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.media ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.media ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.media ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  Address
                </Text>
              </Flex>
            </Tab>
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={mediaTab}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
              onClick={() =>
                setActiveBullets({
                  user: true,
                  address: true,
                  media: true ,
                })
              }
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: '120px', md: '250px', lg: '300px' },
                  height: '3px',
                  bg: activeBullets.profile ? 'white' : 'brand.400',
                  left: { sm: '12px', md: '32px' },
                  top: '6px',
                  position: 'absolute',
                  bottom: activeBullets.address ? '40px' : '38px',
                  transition: 'all .3s ease',
                }}
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.address ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.address ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.address ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  Media
                </Text>
              </Flex>
            </Tab>
          </TabList>
          <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                Business Info
                </Text>
                <form onSubmit={handleSubmitBusinessInfo}>
                  <Flex direction="column" w="100%">
                      <Stack direction="column" spacing="20px">
                          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">

                                <Flex direction="column">
                                  <InputField
                                      mb="0px"
                                      id="username"
                                      placeholder="eg. Simmmple"
                                      label="Title *"
                                      name="username"
                                      onChange={handleChange}
                                      value={input.username}
                                  />
                                  {errors.username &&
                                    <span className="error-message">
                                        {errors.username}
                                    </span>
                                  }
                                </Flex>

                                <Flex direction="column">
                                  <InputField
                                      mb="0px"
                                      id="business-name"
                                      placeholder="eg. Esthera"
                                      label="Business Name *"
                                      name="business_name"
                                      onChange={handleChange}
                                      value={input.business_name}
                                      FormLabel
                                  />
                                  {errors.business_name &&
                                    <span className="error-message">
                                        {errors.business_name}
                                    </span>
                                  }
                                </Flex>

                                <Flex direction="column">
                                  <FormLabel
                                    ms="10px"
                                    htmlFor="business-type"
                                    fontSize="sm"
                                    color=''
                                    fontWeight="bold"
                                    _hover={{ cursor: 'pointer' }}
                                  >
                                    Business Types *
                                  </FormLabel>
          
                                  <Select
                                    id="business-type"
                                    value={input.business_type}
                                    name="business_type"
                                    onChange={handleChange}
                                    borderRadius="16px"
                                  >
                                    <option value=''>Select...</option>
                                    {businessTypes.map((option) => (
                                      <option value={option.id}>{option.title}</option >
                                    ))}
                                  </Select>
                                  {errors.business_type &&
                                    <span className="error-message">
                                        {errors.business_type}
                                    </span>
                                  }
                                </Flex>

                                <Flex direction="column">
                                  <InputField
                                      mb="0px"
                                      id="phone-number"
                                      placeholder="4030120241"
                                      label="Phone Number"
                                      name="phone_number"
                                      onChange={handleChange}
                                      value={input.phone_number}
                                  />
                                  {errors.phone_number &&
                                    <span className="error-message">
                                        {errors.phone_number}
                                    </span>
                                  }
                                </Flex>

                                <Flex direction="column">
                                  <InputField
                                      mb="0px"
                                      id="email"
                                      placeholder="eg. test@gmail.com"
                                      label="Contact Email"
                                      name="email"
                                      onChange={handleChange}
                                      value={input.email}
                                  />
                                  {errors.email &&
                                    <span className="error-message">
                                        {errors.email}
                                    </span>
                                  }
                                </Flex>

                                <Flex direction="column">
                                  <FormLabel
                                    ms="10px"
                                    htmlFor="standout-amenity"
                                    fontSize="sm"
                                    color=''
                                    fontWeight="bold"
                                    _hover={{ cursor: 'pointer' }}
                                  >
                                    Do you have any standout amenities?
                                  </FormLabel>
            
                                  <Multiselect
                                      options={standoutAmenities} // Options to display in the dropdown
                                      selectedValues={businessAmenities} // Preselected value to persist in dropdown
                                      onSelect={handleAmenitySelectValue} // Function will trigger on select event
                                      onRemove={handleAmenitySelectValue} // Function will trigger on remove event
                                      displayValue="name" // Property name to display in the dropdown options
                                      borderRadius="16px"
                                  />
            
                                  {errors.standout_amenity_ids &&
                                    <span className="error-message">
                                        {errors.standout_amenity_ids}
                                    </span>
                                  }
                                </Flex>
                                
                                <Flex direction="column">
                                  <FormLabel
                                    ms="10px"
                                    htmlFor="business-start-time"
                                    fontSize="sm"
                                    color=''
                                    fontWeight="bold"
                                    _hover={{ cursor: 'pointer' }}
                                  >
                                    Business Start Time *
                                  </FormLabel>

                                  <input type='time'mb="0px"
                                      id="business-start-time"
                                      name="business_start_time"
                                      onChange={handleChange}
                                      value={input.business_start_time}
                                      class="css-1t1l5ix"
                                  />
                                  {errors.business_start_time &&
                                    <span className="error-message">
                                        {errors.business_start_time}
                                    </span>
                                  }
                                </Flex>

                                <Flex direction="column">
                                  <FormLabel
                                    ms="10px"
                                    htmlFor="business-end-time"
                                    fontSize="sm"
                                    color=''
                                    fontWeight="bold"
                                    _hover={{ cursor: 'pointer' }}
                                  >
                                    Business End Time *
                                  </FormLabel>

                                  <input type='time'mb="0px"
                                      id="business-end-time"
                                      name="business_end_time"
                                      onChange={handleChange}
                                      value={input.business_end_time}
                                      class="css-1t1l5ix"
                                  />
                                  {errors.business_end_time &&
                                    <span className="error-message">
                                        {errors.business_end_time}
                                    </span>
                                  }
                                </Flex>

                                <Flex direction="column">
                                  <InputField
                                      id="website"
                                      placeholder="Description Title"
                                      label="Website"
                                      name="website"
                                      mb="0px"
                                      onChange={handleChange}
                                      value={input.website}
                                  />
                                  {errors.website &&
                                    <span className="error-message">
                                        {errors.website }
                                    </span>
                                  }
                                </Flex>

                                <Flex direction="column">
                                  <FormLabel
                                    ms="10px"
                                    htmlFor="verified-type"
                                    fontSize="sm"
                                    color=''
                                    fontWeight="bold"
                                    _hover={{ cursor: 'pointer' }}
                                  >
                                    Is Verified *
                                  </FormLabel>
          
                                  <Select
                                    id="verified-type"
                                    value={input.verified_type}
                                    name="verified_type"
                                    onChange={handleChange}
                                    borderRadius="16px"
                                  >
                                    <option value=''>Select...</option>
                                    <option value="1">Yes</option >
                                    <option value="2">No</option >
                                  </Select>
                                  {errors.verified_type &&
                                    <span className="error-message">
                                        {errors.verified_type}
                                    </span>
                                  }
                                </Flex>
                          </SimpleGrid>
                      </Stack>
                      <Stack direction="column" spacing="20px" margin="20px 0px">
                          <Flex direction="column">
                            <TextField
                                minH="200px"
                                id="brief_bio"
                                placeholder="Enter a few words about property"
                                label="Brief bio"
                                name="brief_bio"
                                onChange={handleChange}
                                value={input.brief_bio}
                            />
                            {errors.brief_bio &&
                              <span className="error-message">
                                  {errors.brief_bio }
                              </span>
                            }
                          </Flex>
                      </Stack>
                      <Flex justify="space-between" mt="24px">
                        <Button
                          variant="darkBrand"
                          fontSize="sm"
                          borderRadius="16px"
                          w={{ base: '128px', md: '148px' }}
                          h="46px"
                          ms="auto"
                          type='submit'
                          // onClick={() => addressTab.current.click()}
                        >
                          Next
                        </Button>
                      </Flex>
                  </Flex>
                </form>
              </Card>
            </TabPanel>

            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                  Address
                </Text>
                <Flex direction="column" w="100%">
                  <Stack direction="column" spacing="20px" mb="20px">
                    <InputField
                      mb="0px"
                      id="business-location"
                      placeholder="eg. India"
                      label="Please enter your business address into the search bar of the Map. *"
                      name="business_location"
                      onChange={handleChange}
                      value={input.business_location}
                    />
                    {errors.business_location &&
                      <span className="error-message">
                          {errors.business_location}
                      </span>
                    }
  
                    <InputField
                      mb="0px"
                      id="business-lat"
                      placeholder="eg. 123.5"
                      label="Please enter your Business address latitude. *"
                      name="business_lat"
                      onChange={handleChange}
                      value={input.business_lat}
                    />
                    {errors.business_lat &&
                      <span className="error-message">
                          {errors.business_lat}
                      </span>
                    }
  
                    <InputField
                      mb="0px"
                      id="business_lng"
                      placeholder="eg. 456.2"
                      label="Please enter your Business address longitude. *"
                      name="business_lng"
                      onChange={handleChange}
                      value={input.business_lng}
                    />
                    {errors.business_lng &&
                      <span className="error-message">
                          {errors.business_lng}
                      </span>
                    }
  
                  </Stack>

                  <Flex justify="space-between">
                    <Button
                      variant="light"
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                      onClick={() => userTab.current.click()}
                    >
                      Prev
                    </Button>
                    <Button
                      variant="darkBrand"
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                      ms="auto"
                      type='submit'
                      onClick={handleSubmitBusinessAddress}
                    >
                      Next
                    </Button>
                  </Flex>
                </Flex>
              </Card>
            </TabPanel>

            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                  Media
                </Text>
                <Flex direction="column" w="100%">
                  <FormLabel
                    ms="10px"
                    htmlFor="property_logo"
                    fontSize="sm"
                    color=''
                    fontWeight="bold"
                    _hover={{ cursor: 'pointer' }}
                  >
                    Profile Image
                  </FormLabel>

                  <Dropzone onDrop={handleBusinessImageUpload} accept="image/*" maxFiles={1} minH={200}>
                    {({getRootProps, getInputProps, isDragActive, isDragAccept,isDragReject }) => {
                      const additionalClass = isDragAccept ? "accept" :isDragReject ? "reject" : "";

                      return (
                        <div
                        {...getRootProps({
                          className: `dropzone ${additionalClass}`
                        })}
                        >
                          <input {...getInputProps()} />
                          <p>Drag'n'drop images,or click to select files</p>
                        </div>
                      );
                    }}
                  </Dropzone>
                  {businessImage && (
                      <div className='new_box'>
                        <img src={ASSETS_URL+'business/'+businessImage} className='img-container logo-image' alt='upload profile file' width="50%"/>
                        <button className='remove-image' onClick={() => removeImage(1,businessImage)}>X</button>
                      </div>
                  )}

                  <FormLabel
                    ms="10px"
                    htmlFor="media_images"
                    fontSize="sm"
                    color=''
                    fontWeight="bold"
                    _hover={{ cursor: 'pointer' }}
                  >
                    Media Images
                  </FormLabel>

                  <Dropzone onDrop={handleMediaImageUpload} accept="image/*">
                    {({getRootProps, getInputProps, isDragActive, isDragAccept,isDragReject }) => {
                      const additionalClass = isDragAccept ? "accept" :isDragReject ? "reject" : "";  
                      return (
                        <div
                        {...getRootProps({
                          className: `dropzone ${additionalClass}`
                        })}
                        >
                          <input {...getInputProps()} />
                          <p>Drag'n'drop images,or click to select files</p>
                        </div>
                      );
                    }}
                  </Dropzone>
                  
                  {mediaImages.length > 0 &&
                    <SortableList onSortEnd={onSortEnd} className={classes.root} draggedItemClassName={classes.dragged}>
                    {mediaImages.map((item,index) => (
                      <SortableItem key={ASSETS_URL+'business/media/small/'+item}>
                        <div className={classes.item}>
                          <img src={ASSETS_URL+'business/media/small/'+item} className={classes.image} alt='media' />
                          <button className='remove-image' onClick={() => removeImage(2,item)}>X</button>
                        </div>
                      </SortableItem>
                    ))}
                    </SortableList>
                  }

                  <FormLabel
                    ms="10px"
                    htmlFor="menu_images"
                    fontSize="sm"
                    color=''
                    fontWeight="bold"
                    _hover={{ cursor: 'pointer' }}
                  >
                    Menu Images
                  </FormLabel>

                  <Dropzone onDrop={handleMenuImageUpload} accept="image/*">
                    {({getRootProps, getInputProps, isDragActive, isDragAccept,isDragReject }) => {
                      const additionalClass = isDragAccept ? "accept" :isDragReject ? "reject" : "";  
                      return (
                        <div
                        {...getRootProps({
                          className: `dropzone ${additionalClass}`
                        })}
                        >
                          <input {...getInputProps()} />
                          <p>Drag'n'drop images,or click to select files</p>
                        </div>
                      );
                    }}
                  </Dropzone>
                  
                  {menuImages.length > 0 &&
                    <SortableList onSortEnd={onSortEnd} className={classes.root} draggedItemClassName={classes.dragged}>
                    {menuImages.map((item,index) => (
                      <SortableItem key={ASSETS_URL+'business/menu/small/'+item}>
                        <div className={classes.item}>
                          <img src={ASSETS_URL+'business/menu/small/'+item} className={classes.image} alt='media' />
                          <button className='remove-image' onClick={() => removeImage(3,item)}>X</button>
                        </div>
                      </SortableItem>
                    ))}
                    </SortableList>
                  }

                </Flex> 

                <Flex justify="space-between" mt="24px">
                  <Button
                      variant="darkBrand"
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                      ms="auto"
                      type='submit'
                      onClick={() => redirectToListing()}
                  >
                  Save
                  </Button>
                </Flex>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
  );
}
