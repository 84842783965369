export const columnsDataBusinessTypeOverview = [
    {
      Header: "ACTIONS",
      accessor: "business_type_id",
    },
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Title", 
      accessor: "title",
    },
    {
      Header: "Created At",
      accessor: "date",
    }
  ];
  